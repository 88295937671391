<template>
  <div
    class="views-page is-flex is-flex-direction-column"
    v-if="isShown"
  >
    <div
      class="info-line"
      :class="GetMessagesData.main_color"
    />
    <div class="p-4">
      <div class="is-flex">
        <div class="column p-0">
          <span
            class="tag-mobile has-text-weight-medium is-size-6  tag mr-3 my-1"
            v-for="(item,id) in GetMessagesData.cards"
            :key="`card-${id}`"
            :class="item.color"
          >{{ item.name }}</span>
        </div>
      </div>
      <div class="mt-3">
        <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap-reverse">
          <div>
            <div>
              <b>Скорость, об/мин</b>
            </div>
            <div class="is-size-3 has-text-weight-bold mb-5">
              <p
                class="has-text-success"
                v-if="GetMessagesData.current_values.speed != undefined"
              >{{ GetMessagesData.current_values.speed }}</p>
              <p v-else>0</p>
            </div>
          </div>
          <div v-if="GetMessagesData.image_path">
            <b-image
              :src="getImagePath(GetMessagesData.image_path)"
              style="max-width: 350px; image-rendering: crisp-edges"
            />
          </div>
          <img
            v-else
            class="icon-agent"
            width="280px"
            src="@/assets/14.jpg"
            alt="device-icon"
          >
        </div>
        <b class="">Виброскорость, мм/с</b>
        <div class="mb-5">
          <div
            v-if="GetMessagesData.current_values.vibs.length != 0"
            class="vibs--grid"
            :style="{ gridTemplateRows: `repeat(${GetMessagesData.module_count/4}, 1fr)` }"
          >
            <div
              v-for="(item, id) in GetMessagesData.current_values.vibs"
              :key="`vib-${id}`"
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mr-2"
            >
              <router-link
                class="is-size-4-mobile is-size-3-tablet has-text-weight-bold"
                :class="item.color"
                :to="{ path: `/device/trends/vel/${device_id}` }"
              >{{ item.value }}</router-link>
              <router-link
                class="is-underlined has-text-grey-light"
                :to="{ path: `/device/trends/vel/${device_id}` }"
              >{{ item.name }}</router-link>
              <span>{{ showHealth(item.health) }}</span>
            </div>
            <div
              class="is-flex is-flex-direction-column has-text-centered"
              v-for="(item,idx) in GetMessagesData.temperatures"
              :key="`temp-${idx}`"
            >
              <router-link
                v-if="item.value"
                class="is-size-4-mobile is-size-3-tablet has-text-weight-bold mr-3"
                :to="{ path: `/device/trends/vel/${device_id}` }"
                :class="item.color"
              >{{ item.value }}</router-link>
              <router-link
                class="is-underlined has-text-grey has-text-weight-light"
                :to="{ path: `/device/trends/vel/${device_id}` }"
              >{{ item.name }}</router-link>
            </div>
          </div>

          <div
            v-else
            class="vibs--grid"
            :style="{ gridTemplateRows: `repeat(${GetMessagesData.module_count/4}, 1fr)` }"
          >
            <div
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center mx-4"
              v-for="(item,idx) in GetMessagesData.module_count"
              :key="idx"
            >
              <div
                class="is-size-4-mobile is-size-3-tablet has-text-weight-bold has-text-grey"
                :to="{ path: `/device/trends/${device_id}` }"
                :class="item.color"
              >0
              </div>
              <router-link
                class="is-underlined has-text-grey has-text-weight-light"
                :to="{ path: `/device/trends/${device_id}` }"
              >VIB{{ item }}
              </router-link>
            </div>
          </div>
        </div>

<!--        <div-->
<!--          v-if="GetMessagesData.temperature_count > 0"-->
<!--          class="temperatures&#45;&#45;grid"-->
<!--          :style="{ gridTemplateRows: `repeat(${GetMessagesData.temperatures.length/4}, 1fr)` }"-->
<!--        >-->
<!--          <div-->
<!--            class="is-flex is-flex-direction-column has-text-centered"-->
<!--            v-for="(item,idx) in GetMessagesData.temperatures"-->
<!--            :key="idx"-->
<!--          >-->
<!--            <router-link-->
<!--              v-if="item.value"-->
<!--              class="is-size-4-mobile is-size-3-tablet has-text-weight-bold mr-3"-->
<!--              :to="{ path: `/device/trends/${device_id}` }"-->
<!--              :class="item.color"-->
<!--            >{{ item.value }}</router-link>-->
<!--            <router-link-->
<!--              class="is-underlined has-text-grey has-text-weight-light"-->
<!--              :to="{ path: `/device/trends/${device_id}` }"-->
<!--            >{{ item.name }}</router-link>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div v-else>-->

<!--          &lt;!&ndash; <div class="is-flex is-flex-direction-column has-text-centered" v-for="(item,idx) in GetMessagesData.temperatures" :key="idx" >-->
<!--            <router-link v-if="item.value" class="is-size-3 has-text-weight-bold mr-3" :to="{ path: '/device/trends/' + device_id }"  :class="item.color">{{item.value}}</router-link>-->
<!--            <router-link class="is-underlined has-text-grey has-text-weight-light" :to="{ path: '/device/trends/' + device_id }" >{{item.name}}</router-link>-->
<!--          </div> &ndash;&gt;-->
<!--        </div>-->

        <template v-if="messagesCount > 0">
          <p class="mb-3 has-text-grey">Кол-во сообщений: {{ messagesCount }}</p>
          <div class="is-flex is-align-items-center">
            <div class="pr-2 is-clickable" @click="currentSlide--">
              <font-awesome-icon
                :icon="['fas', 'fa-arrow-left']"
              ></font-awesome-icon>
            </div>
            <b-carousel
              v-model="currentSlide"
              :indicator="false"
              :arrow="false"
              icon-pack="fas"
              icon-size="is-medium"
              :autoplay="false"
              :repeat="false"
              :arrow-hover="false"
            >
              <b-carousel-item v-for="(message, idx) in messages" :key="message.id">
                <section :class="[message.color]" draggable="true">
                  <div class="is-flex px-3 py-3" draggable="true">
                    <p draggable="true">
                      <font-awesome-icon
                        draggable="true"
                        class="px-1 mr-2"
                        :icon="['fas', 'fa-triangle-exclamation']"
                      ></font-awesome-icon>
                    </p>
                    <div class="is-flex is-flex-direction-column" draggable="true">
                      <p draggable="true">{{ message.msg }}</p>
                      <p class="has-text-grey has-text-weight-light" draggable="true">{{ message.date }}</p>
                    </div>
                  </div>
                </section>
              </b-carousel-item>
            </b-carousel>
            <div class="ml-2 is-clickable" @click="currentSlide++">
              <font-awesome-icon
                :icon="['fas', 'fa-arrow-right']"
              ></font-awesome-icon>
            </div>
          </div>
        </template>
        <div v-if="GetMessagesData.healths && GetMessagesData.healths.length > 0" class="mb-4">
          <p
            v-for="msg in GetMessagesData.healths"
            :key="`health-${msg.id}`"
            class="mb-2"
          >
            {{ msg.msg }}
          </p>
        </div>
        <p class="updated__text">обновлен {{ GetMessagesData.last_active_at }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: ["device_id"],
  data() {
    return {
      currentSlide: 0,
      isShown: false,
      messages: [],
      messagesCount: 0,
      currentAgent: undefined,
      interval: null,
    };
  },
  computed: mapGetters(["GetMessagesData"]),
  watch: {
    GetMessagesData() {
      this.isShown = true;
    },
  },
  mounted() {
    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g, "");
    this.FetchMessages(this.device_id);
    (async() => {
      await this.fetchAgents()
      await this.getMessages()
    })()

    if(this.device_id) this.interval = setInterval(() => this.FetchMessages(this.device_id), 60 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapMutations(["ChangeNavName"]),
    ...mapActions(["FetchMessages"]),
    showHealth(health) {
      return !isNaN(health) ? `${Number(health).toFixed(1)}%` : '';
    },
    getImagePath(path) {
      return `${process.env.VUE_APP_API_URL}${path}`
    },
    async fetchAgents() {
      await this.$axios.get(`user/agents`).then(response => {
        this.currentAgent = response.data?.data.find(agent => agent.id === Number(this.device_id));
        if(this.currentAgent) this.ChangeNavName(`${this.currentAgent.device_name}`)
        else this.ChangeNavName('Обзор')
      });
    },
    async getMessages(page) {
      this.currentPage = page || 1;
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/unread-messages/0/100`);
        this.messages = data?.data || [];
        this.messagesCount = data?.count || 0;
      } catch (e) {
        throw new Error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.carousel {
  min-height: auto;
  width: 100%;
}
.carousel-arrow {
  .icon.has-icons-left {
    left: -2rem !important;
  }
  .icon.has-icons-right {
    right: -2rem !important;
  }
}
</style>
